import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Skeleton, message, Modal, Table, Tooltip } from "antd";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import styles from "./HistoryBarangKeluar.module.css";
import axios from "axios";
import { BASE_API_URL } from "../../../../constant/url";
const { confirm } = Modal;

function HistoryBarangKeluar() {
  const role = localStorage.getItem("role");
  const acccesToken = localStorage.getItem("accessToken");
  const navigate = useNavigate();
  const [startIndex, setStartIndex] = useState(1);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [open, setOpen] = useState(false);
  const logout = () => {
    localStorage.removeItem("accessToken");
    localStorage.removeItem("role");
    localStorage.removeItem("user");
    localStorage.removeItem("token");
    message.error("Logout berhasil");
    navigate("/");
  };
  const [loading, setLoading] = useState(false);
  const [getAllSpending, setGetAllSpending] = useState([]);
  const [getAllInventory, setGetAllInventory] = useState([]);
  const [openAlert, setOpenAlert] = useState(false);
  const [messageVal, setMessageVal] = useState("");
  const [searchTerm, setSearchTerm] = useState([]);
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const params = useParams();
  const { id } = params;


  const columns = [
    {
      title: "No",
      dataIndex: "index",
      key: "index",
      render: (text, record, index) => <span>{startIndex + index}</span>,
    },
    {
      title: "Tanggal Pengeluaran",
      dataIndex: "spendingsdate",
      key: "spendingsdate",
      render: (startDate) => {
        const options = { year: 'numeric', month: 'long', day: 'numeric' };
        const formattedDate = new Date(startDate).toLocaleDateString('id-ID', options);
        return <span>{formattedDate}</span>;
      },
    },
    {
      title: "Nama Pengeluaran",
      dataIndex: "inventoryname",
      key: "inventoryname",
    },
    {
      title: "Nomor Pengeluaran",
      dataIndex: "nospendings",
      key: "nospendings",
    },
    {
      title: "Jumlah Pengeluaran",
      dataIndex: "totalspendings",
      key: "totalspendings",
      render: (totalspendings) => {
        const formattedTotal = parseFloat(totalspendings).toLocaleString();
        return <span>{formattedTotal}</span>;
      },
      align: 'right',
    },
    {
      title: "Satuan",
      dataIndex: "unitspendings",
      key: "unitspendings",
    },
    {
      title: "Lokasi",
      dataIndex: "locationspendings",
      key: "locationspendings",
    },
    {
      title: "Diminta Oleh",
      dataIndex: "requestspendings",
      key: "requestspendings",
    },
    {
      title: "Disetujui Oleh",
      dataIndex: "acceptspendings",
      key: "acceptspendings",
    },
  ];

  useEffect(() => {
    setLoading(true);
    let config = {
      method: "get",
      url: `${BASE_API_URL}/v1/spendings/list-spendingbyid?inventories_id=${id}`,
      headers: {
        Authorization: `Bearer ${acccesToken}`,
      },
    };

    axios(config)
      .then(function (response) {
        setGetAllSpending(response.data.data);
        setSearchTerm(response.data.data);
        console.log(response.data.data);
        setLoading(false);
      })
      .catch(function (error) {
        console.log(error);
        if (error.response.status === 401) {
          logout();
          message.error("Your session has expired, please log in again");
        }
        setLoading(false);
      });
  }, []);

  //List Inventories
  useEffect(() => {
    setLoading(true);
    let config = {
      method: "get",
      url: `${BASE_API_URL}/v1/inventory/list-inventory`,
      headers: {
        Authorization: `Bearer ${acccesToken}`,
      },
    };

    axios(config)
      .then(function (response) {
        setGetAllInventory(response.data.data);
        console.log("pengelyara", response.data.data);
        setLoading(false);
      })
      .catch(function (error) {
        console.log(error);
        if (error.response.status === 401) {
          logout();
          message.error("Your session has expired, please log in again");
        }
        setLoading(false);
      });
  }, []);

  const deleteSpending = (value) => {
    confirm({
      title: "Are you sure you want to delete the items ?",
      icon: <ExclamationCircleOutlined />,
      okText: "Delete",
      okType: "danger",
      cancelText: "Cancel",
      onOk() {
        let config = {
          method: "delete",
          url: `${BASE_API_URL}/v1/spendings/deletespending/${value}`,
          headers: {
            Authorization: `Bearer ${acccesToken}`,
            "Content-Type": "application/json",
          },
        };
        axios(config)
          .then(function (response) {
            message.success("Delete Inventory successfully");
            window.location.reload();
          })
          .catch(function (error) {
            message.error("Delete Items Failed");
            if (error.response.status === 401) {
              logout();
              message.error("Your session has expired, please log in again");
            }
            setLoading(false);
          });
      },
      onCancel() { },
    });
  };

  //excel

  const handledownload = async () => {
    setLoading(true);

    try {
      const response = await axios.post(
        `${BASE_API_URL}/v1/spendings/spendings-excel`,
        { startDate, endDate },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
          },
          responseType: "blob",
        }
      );

      // Create a Blob from the binary data
      const blob = new Blob([response.data], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });

      const link = document.createElement("a");
      link.href = window.URL.createObjectURL(blob);
      link.download = "Spendings.xlsx";
      document.body.appendChild(link);

      link.click();

      setLoading(false);
    } catch (error) {
      setLoading(false);
      if (error.response && error.response.status === 401) {
        logout();
        setOpenAlert(true);
        setMessageVal(`${error.response.data.message}`);
      } else if (error.response && error.response.data.statusCode === 4002) {
        setOpenAlert(true);
        setMessageVal(`${error.response.data.message}`);
      } else if (error.response && error.response.status === 400) {
        setOpenAlert(true);
        setMessageVal(`${error.response.data.message}`);
      } else {
        message.error("Unknown Error");
      }
      console.log(error);
    }
  };

  const mergedData = getAllSpending.map((spendingItem) => {
    const inventoryItem = getAllInventory.find(
      (inventory) =>
        String(inventory.id) === String(spendingItem.inventories_id)
    );

    return {
      ...spendingItem,
      inventoryname: inventoryItem ? inventoryItem.inventoryname : null,
    };
  });
  mergedData.sort((a, b) => new Date(b.spendingsdate) - new Date(a.spendingsdate));

  // console.log("mergedata", mergedData);

  const handleChangeSearch = (e) => {
    const searchText = e.target.value.toLowerCase();

    if (searchText === "") {
      setGetAllSpending(searchTerm);
    } else {
      const filteredData = getAllSpending
        .map((registerItem) => {
          const inventoryItem = getAllInventory.find(
            (inventory) =>
              String(inventory.id) === String(registerItem.inventories_id)
          );

          return {
            ...registerItem,
            inventoryname: inventoryItem ? inventoryItem.inventoryname : null,
          };
        })
        .filter((item) =>
          Object.values(item).some(
            (value) =>
              typeof value === "string" &&
              value.toLowerCase().includes(searchText)
          )
        );

      setGetAllSpending(filteredData);
    }
  };

  return (
    <div className={styles.wrapper}>
      {loading ? (
        <Skeleton />
      ) : (
        <div className={styles.main}>
          <div>
            <Table
              className={styles.tableCustomers}
              bordered
              dataSource={mergedData}
              columns={columns}
              pagination={{
                pageSize: 10,
                onChange: (page, pageSize) => {
                  setStartIndex((page - 1) * pageSize + 1);
                },
              }}
            />
          </div>
        </div>
      )}
    </div>
  );
}

export default HistoryBarangKeluar;
