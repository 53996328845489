import React, { useState } from "react";
import { Skeleton } from "antd";
import {
  DashboardColor,
  DashboardDark,
  UserColor,
  UserDark,
  CustColor,
  CustDark,
  JobColor,
  JobDark,
  InventoryColor,
  InventoryDark,
  DeliveryColor,
  DeliveryDark,
  ListColor,
  ListDark,
  NoteWhite,
  NoteBlack,
  StockBlack,
  StockWhite,
} from "../../../assets";
import { IoMdArrowDropdown } from "react-icons/io";
import { NavLink, useMatch, useLocation } from "react-router-dom";
import styles from "./DashboardSidebar.module.css";

function DashboardSidebar() {
  const [loading, setLoading] = useState(false);
  const location = useLocation();
  const role = localStorage.getItem("role");
  const [show, setShow] = useState(false);

  return (
    <div className={styles.wrapper}>
      {loading ? (
        <Skeleton />
      ) : (
        <div className={styles.container}>
          {role === "superadmin" && (
            <>
              {location.pathname.slice(0, 10) === "/dashboard" ? (
                <NavLink
                  to="/dashboard"
                  end
                  className={styles.linkSidebarActive}
                >
                  <img
                    src={DashboardColor}
                    alt="icon sidebar"
                    className={styles.iconSidebar}
                  />
                  <h5 className={styles.textColor}>Dashboard</h5>
                </NavLink>
              ) : (
                <NavLink to="/dashboard" end className={styles.linkSidebar}>
                  <img
                    src={DashboardDark}
                    alt="icon sidebar"
                    className={styles.iconSidebar}
                  />
                  <h5 className={styles.text}>Dashboard</h5>
                </NavLink>
              )}
              {location.pathname.slice(0, 12) === "/bahan-baku" ? (
                <NavLink
                  to="/bahan-baku"
                  end
                  className={styles.linkSidebarActive}
                >
                  <img
                    src={ListColor}
                    alt="icon sidebar"
                    className={styles.iconSidebar}
                  />
                  <h5 className={styles.textColor}>Komitmen Bahan Baku</h5>
                </NavLink>
              ) : (
                <NavLink to="/bahan-baku" end className={styles.linkSidebar}>
                  <img
                    src={ListDark}
                    alt="icon sidebar"
                    className={styles.iconSidebar}
                  />
                  <h5 className={styles.text}>Komitmen Bahan Baku</h5>
                </NavLink>
              )}
              {location.pathname.slice(0, 16) === "/kategori-barang" ? (
                <NavLink
                  to="/kategori-barang"
                  end
                  className={styles.linkSidebarActive}
                >
                  <img
                    src={NoteWhite}
                    alt="icon sidebar"
                    className={styles.iconSidebar}
                  />
                  <h5 className={styles.textColor}>Kategori</h5>
                </NavLink>
              ) : (
                <NavLink
                  to="/kategori-barang"
                  end
                  className={styles.linkSidebar}
                >
                  <img
                    src={NoteBlack}
                    alt="icon sidebar"
                    className={styles.iconSidebar}
                  />
                  <h5 className={styles.text}>Kategori</h5>
                </NavLink>
              )}
              {location.pathname.slice(0, 12) === "/persediaan" ? (
                <NavLink
                  to="/persediaan"
                  end
                  className={styles.linkSidebarActive}
                >
                  <img
                    src={InventoryColor}
                    alt="icon sidebar"
                    className={styles.iconSidebar}
                  />
                  <h5 className={styles.textColor}>Persediaan</h5>
                </NavLink>
              ) : (
                <NavLink to="/persediaan" end className={styles.linkSidebar}>
                  <img
                    src={InventoryDark}
                    alt="icon sidebar"
                    className={styles.iconSidebar}
                  />
                  <h5 className={styles.text}>Persediaan</h5>
                </NavLink>
              )}
              {/* {location.pathname.slice(0, 7) === "/opname" ? (
                <NavLink
                  to="/opname"
                  end
                  className={styles.linkSidebarActive}
                >
                  <img
                    src={StockWhite}
                    alt="icon sidebar"
                    className={styles.iconSidebar}
                  />
                  <h5 className={styles.textColor}>Stock Opname</h5>
                </NavLink>
              ) : (
                <NavLink to="/opname" end className={styles.linkSidebar}>
                  <img
                    src={StockBlack}
                    alt="icon sidebar"
                    className={styles.iconSidebar}
                  />
                  <h5 className={styles.text}>Stock Opname</h5>
                </NavLink>
              )} */}
              {location.pathname.slice(0, 12) === "/barangMasuk" ? (
                <NavLink
                  to="/barangMasuk"
                  end
                  className={styles.linkSidebarActive}
                >
                  <img
                    src={ListColor}
                    alt="icon sidebar"
                    className={styles.iconSidebar}
                  />
                  <h5 className={styles.textColor}>Barang Masuk</h5>
                </NavLink>
              ) : (
                <NavLink to="/barangMasuk" end className={styles.linkSidebar}>
                  <img
                    src={ListDark}
                    alt="icon sidebar"
                    className={styles.iconSidebar}
                  />
                  <h5 className={styles.text}>Barang Masuk</h5>
                </NavLink>
              )}

              {location.pathname.slice(0, 12) === "/pengeluaran" ? (
                <NavLink
                  to="/pengeluaran"
                  end
                  className={styles.linkSidebarActive}
                >
                  <img
                    src={DeliveryColor}
                    alt="icon sidebar"
                    className={styles.iconSidebar}
                  />
                  <h5 className={styles.textColor}>Barang Keluar</h5>
                </NavLink>
              ) : (
                <NavLink to="/pengeluaran" end className={styles.linkSidebar}>
                  <img
                    src={DeliveryDark}
                    alt="icon sidebar"
                    className={styles.iconSidebar}
                  />
                  <h5 className={styles.text}>Barang Keluar</h5>
                </NavLink>
              )}

              {location.pathname.slice(0, 12) === "/register" ? (
                <NavLink
                  to="/register"
                  end
                  className={styles.linkSidebarActive}
                >
                  <img
                    src={CustColor}
                    alt="icon sidebar"
                    className={styles.iconSidebar}
                  />
                  <h5 className={styles.textColor}>Akun</h5>
                </NavLink>
              ) : (
                <NavLink to="/register" end className={styles.linkSidebar}>
                  <img
                    src={CustDark}
                    alt="icon sidebar"
                    className={styles.iconSidebar}
                  />
                  <h5 className={styles.text}>Akun</h5>
                </NavLink>
              )}
              {/* {location.pathname.slice(0, 3) === "/do" ? (
                <NavLink to="/do" end className={styles.linkSidebarActive}>
                  <img
                    src={CustColor}
                    alt="icon sidebar"
                    className={styles.iconSidebar}
                  />
                  <h5 className={styles.textColor}>DO</h5>
                </NavLink>
              ) : (
                <NavLink to="/do" end className={styles.linkSidebar}>
                  <img
                    src={CustDark}
                    alt="icon sidebar"
                    className={styles.iconSidebar}
                  />
                  <h5 className={styles.text}>DO</h5>
                </NavLink>
              )} */}
            </>
          )}
          {role === "admin" && (
            <>
              {location.pathname.slice(0, 10) === "/dashboard" ? (
                <NavLink
                  to="/dashboard"
                  end
                  className={styles.linkSidebarActive}
                >
                  <img
                    src={DashboardColor}
                    alt="icon sidebar"
                    className={styles.iconSidebar}
                  />
                  <h5 className={styles.textColor}>Dashboard</h5>
                </NavLink>
              ) : (
                <NavLink to="/dashboard" end className={styles.linkSidebar}>
                  <img
                    src={DashboardDark}
                    alt="icon sidebar"
                    className={styles.iconSidebar}
                  />
                  <h5 className={styles.text}>Dashboard</h5>
                </NavLink>
              )}
              {location.pathname.slice(0, 12) === "/bahan-baku" ? (
                <NavLink
                  to="/bahan-baku"
                  end
                  className={styles.linkSidebarActive}
                >
                  <img
                    src={ListColor}
                    alt="icon sidebar"
                    className={styles.iconSidebar}
                  />
                  <h5 className={styles.textColor}>Komitmen Bahan Baku</h5>
                </NavLink>
              ) : (
                <NavLink to="/bahan-baku" end className={styles.linkSidebar}>
                  <img
                    src={ListDark}
                    alt="icon sidebar"
                    className={styles.iconSidebar}
                  />
                  <h5 className={styles.text}>Komitmen Bahan Baku</h5>
                </NavLink>
              )}{location.pathname.slice(0, 21) === "/pemasukan-bahan-baku" ? (
                <NavLink
                  to="/pemasukan-bahan-baku"
                  end
                  className={styles.linkSidebarActive}
                >
                  <img
                    src={ListColor}
                    alt="icon sidebar"
                    className={styles.iconSidebar}
                  />
                  <h5 className={styles.textColor}>Pemasukan Bahan Baku</h5>
                </NavLink>
              ) : (
                <NavLink to="/pemasukan-bahan-baku" end className={styles.linkSidebar}>
                  <img
                    src={ListDark}
                    alt="icon sidebar"
                    className={styles.iconSidebar}
                  />
                  <h5 className={styles.text}>Pemasukan Bahan Baku</h5>
                </NavLink>
              )}
              {location.pathname.slice(0, 16) === "/kategori-barang" ? (
                <NavLink
                  to="/kategori-barang"
                  end
                  className={styles.linkSidebarActive}
                >
                  <img
                    src={NoteWhite}
                    alt="icon sidebar"
                    className={styles.iconSidebar}
                  />
                  <h5 className={styles.textColor}>Kategori</h5>
                </NavLink>
              ) : (
                <NavLink
                  to="/kategori-barang"
                  end
                  className={styles.linkSidebar}
                >
                  <img
                    src={NoteBlack}
                    alt="icon sidebar"
                    className={styles.iconSidebar}
                  />
                  <h5 className={styles.text}>Kategori</h5>
                </NavLink>
              )}
              {location.pathname.slice(0, 12) === "/persediaan" ? (
                <NavLink
                  to="/persediaan"
                  end
                  className={styles.linkSidebarActive}
                >
                  <img
                    src={InventoryColor}
                    alt="icon sidebar"
                    className={styles.iconSidebar}
                  />
                  <h5 className={styles.textColor}>Persediaan</h5>
                </NavLink>
              ) : (
                <NavLink to="/persediaan" end className={styles.linkSidebar}>
                  <img
                    src={InventoryDark}
                    alt="icon sidebar"
                    className={styles.iconSidebar}
                  />
                  <h5 className={styles.text}>Persediaan</h5>
                </NavLink>
              )}
              {/* {location.pathname.slice(0, 7) === "/opname" ? (
                <NavLink
                  to="/opname"
                  end
                  className={styles.linkSidebarActive}
                >
                  <img
                    src={StockWhite}
                    alt="icon sidebar"
                    className={styles.iconSidebar}
                  />
                  <h5 className={styles.textColor}>Stock Opname</h5>
                </NavLink>
              ) : (
                <NavLink to="/opname" end className={styles.linkSidebar}>
                  <img
                    src={StockBlack}
                    alt="icon sidebar"
                    className={styles.iconSidebar}
                  />
                  <h5 className={styles.text}>Stock Opname</h5>
                </NavLink>
              )} */}
              {location.pathname.slice(0, 12) === "/barangMasuk" ? (
                <NavLink
                  to="/barangMasuk"
                  end
                  className={styles.linkSidebarActive}
                >
                  <img
                    src={ListColor}
                    alt="icon sidebar"
                    className={styles.iconSidebar}
                  />
                  <h5 className={styles.textColor}>Barang Masuk</h5>
                </NavLink>
              ) : (
                <NavLink to="/barangMasuk" end className={styles.linkSidebar}>
                  <img
                    src={ListDark}
                    alt="icon sidebar"
                    className={styles.iconSidebar}
                  />
                  <h5 className={styles.text}>Barang Masuk</h5>
                </NavLink>
              )}

              {location.pathname.slice(0, 12) === "/pengeluaran" ? (
                <NavLink
                  to="/pengeluaran"
                  end
                  className={styles.linkSidebarActive}
                >
                  <img
                    src={DeliveryColor}
                    alt="icon sidebar"
                    className={styles.iconSidebar}
                  />
                  <h5 className={styles.textColor}>Barang Keluar</h5>
                </NavLink>
              ) : (
                <NavLink to="/pengeluaran" end className={styles.linkSidebar}>
                  <img
                    src={DeliveryDark}
                    alt="icon sidebar"
                    className={styles.iconSidebar}
                  />
                  <h5 className={styles.text}>Barang Keluar</h5>
                </NavLink>
              )}
            </>
          )}
          {role === "user" && (
            <>
              {location.pathname.slice(0, 10) === "/dashboard" ? (
                <NavLink
                  to="/dashboard"
                  end
                  className={styles.linkSidebarActive}
                >
                  <img
                    src={DashboardColor}
                    alt="icon sidebar"
                    className={styles.iconSidebar}
                  />
                  <h5 className={styles.textColor}>Dashboard</h5>
                </NavLink>
              ) : (
                <NavLink to="/dashboard" end className={styles.linkSidebar}>
                  <img
                    src={DashboardDark}
                    alt="icon sidebar"
                    className={styles.iconSidebar}
                  />
                  <h5 className={styles.text}>Dashboard</h5>
                </NavLink>
              )}
              {location.pathname.slice(0, 12) === "/persediaan" ? (
                <NavLink
                  to="/persediaan"
                  end
                  className={styles.linkSidebarActive}
                >
                  <img
                    src={InventoryColor}
                    alt="icon sidebar"
                    className={styles.iconSidebar}
                  />
                  <h5 className={styles.textColor}>Persediaan</h5>
                </NavLink>
              ) : (
                <NavLink to="/persediaan" end className={styles.linkSidebar}>
                  <img
                    src={InventoryDark}
                    alt="icon sidebar"
                    className={styles.iconSidebar}
                  />
                  <h5 className={styles.text}>Persediaan</h5>
                </NavLink>
              )}
              {/* {location.pathname.slice(0, 7) === "/opname" ? (
                <NavLink
                  to="/opname"
                  end
                  className={styles.linkSidebarActive}
                >
                  <img
                    src={StockWhite}
                    alt="icon sidebar"
                    className={styles.iconSidebar}
                  />
                  <h5 className={styles.textColor}>Stock Opname</h5>
                </NavLink>
              ) : (
                <NavLink to="/opname" end className={styles.linkSidebar}>
                  <img
                    src={StockBlack}
                    alt="icon sidebar"
                    className={styles.iconSidebar}
                  />
                  <h5 className={styles.text}>Stock Opname</h5>
                </NavLink>
              )} */}
            </>
          )}
        </div>
      )}
    </div>
  );
}

export default DashboardSidebar;
