import React, { useState } from "react";
import styles from "./Persediaan.module.css";
import {
  Input,
  Breadcrumb,
  message,
  Alert,
  Select,
  Radio,
  DatePicker,
  Space,
  Skeleton,
  Table,
  Tooltip,
  Modal,
} from "antd";
import { ExclamationCircleOutlined, HomeOutlined } from "@ant-design/icons";
import axios from "axios";
import { NavLink, useNavigate } from "react-router-dom";
import { BASE_API_URL } from "../../../../constant/url";
import { AiFillDelete, AiFillEdit, AiFillEye, AiOutlineHistory } from "react-icons/ai";
import { useEffect } from "react";
import Search from "antd/es/input/Search";
import DashboardLayout from "../../../../layouts/dashboard-layout/DashboardLayout";
// import { Table } from "react-bootstrap";
const { RangePicker } = DatePicker;
const onChange = (value, dateString) => {
  console.log("Selected Time: ", value);
  console.log("Formatted Selected Time: ", dateString);
};
const onOk = (value) => {
  console.log("onOk: ", value);
};
const { confirm } = Modal;

function Persediaan() {
  const role = localStorage.getItem("role");
  const acccesToken = localStorage.getItem("accessToken");
  const navigate = useNavigate();
  const [startIndex, setStartIndex] = useState(1);
  const [openAlert, setOpenAlert] = useState(false);
  const [messageVal, setMessageVal] = useState("");
  const logout = () => {
    localStorage.removeItem("accessToken");
    localStorage.removeItem("role");
    localStorage.removeItem("user");
    localStorage.removeItem("token");
    message.error("Logout berhasil");
    navigate("/");
  };
  const [loading, setLoading] = useState(false);
  const [getAllInventory, setGetAllInventory] = useState([]);
  const [getAllListKategoriBarang, setGetAllListKategoriBarang] = useState([]);
  const [searchTerm, setSearchTerm] = useState([]);
  const [statusSelected, setStatusSelected] = useState("all");
  const [dataCommitment, setDataCommitment] = useState([]);
  const [ItemCategorySelected, setItemCategorySelected] = useState("all");
  const [searchTerm2, setSearchTerm2] = useState([]);
  const [costCenterSelected, setCostCenterSelecte] = useState([]);
  const [selectedDate, setSelectedDate] = useState('');
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const columns = [
    {
      title: "No",
      width: "10%",
      dataIndex: "index",
      key: "index",
      render: (text, record, index) => <span>{startIndex + index}</span>,
    },
    {
      title: "Nama Persediaan",
      dataIndex: "inventoryname",
      key: "inventoryname",
    },
    {
      title: "Kategori",
      dataIndex: "itemcategory",
      key: "itemcategory",
    },
    {
      title: "Jumlah Persediaan",
      dataIndex: "total",
      key: "total",
      align: "right",
      render: (total) => {
        const formattedTotal = parseFloat(total).toLocaleString();
        return <span>{formattedTotal}</span>;
      },
    },
    {
      title: "Satuan",
      dataIndex: "unit",
      key: "unit",
    },
    {
      title: "Waktu terakhir diupdate",
      dataIndex: "last_modified",
      key: "last_modified",
      render: (last_modified) => {
        const dateOptions = { year: 'numeric', month: 'long', day: 'numeric' };
        const timeOptions = { hour: '2-digit', minute: '2-digit', hour12: false, timeZone: 'Asia/Makassar' }; // Zona waktu WITA

        if (last_modified === null) {
          return <span>-</span>;
        } else {
          const formattedDate = new Date(last_modified).toLocaleDateString('id-ID', dateOptions);
          let formattedTime = new Date(last_modified).toLocaleTimeString('id-ID', timeOptions).replace(',', ''); // Format "10.01"
          formattedTime = formattedTime.replace(/\./g, ':'); // Ubah titik menjadi titik dua

          return <span>{formattedDate} , {formattedTime} WITA</span>; // Tampilkan dengan zona waktu WITA
        }
      }
    },

    // {
    //   title: "Tanggal Kadaluwarsa Barang",
    //   dataIndex: "date_expired",
    //   key: "date_expired",
    //   render: (startDate) => {
    //     const options = { year: 'numeric', month: 'long', day: 'numeric' };
    //     if (startDate === null) {
    //       return <span>-</span>;
    //     } else {
    //       const formattedDate = new Date(startDate).toLocaleDateString('id-ID', options);
    //       return <span>{formattedDate}</span>;
    //     }
    //   }
    // },

    {
      title: "Action",
      width: "5%",
      key: "action",
      render: (_, record) => (
        <div className={styles.wrapperIcon}>
          <Space size="middle">
            <NavLink to={`/persediaan/detail-persediaan/${record.id}`}>
              <Tooltip
                placement="bottom"
                title={`Klik untuk lihat history persediaan barang`}
              >
                <AiOutlineHistory className={styles.iconActionView} />
              </Tooltip>
            </NavLink>
          </Space>
          <div className={styles.btngroup}>
            <NavLink
              to={`/persediaan/edit-persediaan/${record.id}`}
              className={styles.btnEditCustomer}
            >
              <AiFillEdit />
            </NavLink>
          </div>
        </div>
      ),
    },
  ];

  useEffect(() => {
    setLoading(true);
    let config = {
      method: "get",
      url: `${BASE_API_URL}/v1/inventory/list-inventory`,
      headers: {
        Authorization: `Bearer ${acccesToken}`,
      },
    };

    axios(config)
      .then(function (response) {
        setGetAllInventory(response.data.data);
        setSearchTerm(response.data.data);
        console.log(response.data.data);
        setLoading(false);
      })
      .catch(function (error) {
        console.log(error);
        if (error.response.status === 401) {
          logout();
          message.error("Your session has expired, please log in again");
        }
        setLoading(false);
      });
  }, []);

  //List kategori
  useEffect(() => {
    setLoading(true);
    let config = {
      method: "get",
      url: `${BASE_API_URL}/v1/sparepart/list-sparepart-category`,
      headers: {
        Authorization: `Bearer ${acccesToken}`,
      },
    };

    axios(config)
      .then(function (response) {
        setGetAllListKategoriBarang(response.data.data);
        console.log(response.data.data);
        setLoading(false);
      })
      .catch(function (error) {
        console.log(error);
        if (error.response.status === 401) {
          logout();
          message.error("Your session has expired, please log in again");
        }
        setLoading(false);
      });
  }, []);

  const deleteInventory = (value) => {
    confirm({
      title: "Are you sure you want to delete the items ?",
      icon: <ExclamationCircleOutlined />,
      okText: "Delete",
      okType: "danger",
      cancelText: "Cancel",
      onOk() {
        let config = {
          method: "delete",
          url: `${BASE_API_URL}/v1/inventory/deleteinventory/${value}`,
          headers: {
            Authorization: `Bearer ${acccesToken}`,
            "Content-Type": "application/json",
          },
        };
        axios(config)
          .then(function (response) {
            message.success("Delete Inventory successfully");
            window.location.reload();
          })
          .catch(function (error) {
            message.error("Delete Items Failed");
            if (error.response.status === 401) {
              logout();
              message.error("Your session has expired, please log in again");
            }
            setLoading(false);
          });
      },
      onCancel() { },
    });
  };

  //excel
  const handledownload = async () => {
    setLoading(true);

    try {
      const response = await axios.post(
        `${BASE_API_URL}/v1/inventory/inventory-excel`,
        { startDate, endDate },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
          },
          responseType: "blob",
        }
      );

      // Create a Blob from the binary data
      const blob = new Blob([response.data], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });

      const link = document.createElement("a");
      link.href = window.URL.createObjectURL(blob);
      link.download = "Persediaan.xlsx";
      document.body.appendChild(link);

      link.click();

      setLoading(false);
    } catch (error) {
      setLoading(false);
      if (error.response && error.response.status === 401) {
        logout();
        setOpenAlert(true);
        setMessageVal(`${error.response.data.message}`);
      } else if (error.response && error.response.data.statusCode === 4002) {
        setOpenAlert(true);
        setMessageVal(`${error.response.data.message}`);
      } else if (error.response && error.response.status === 400) {
        setOpenAlert(true);
        setMessageVal(`${error.response.data.message}`);
      } else {
        message.error("Unknown Error");
      }
      console.log(error);
    }
  };


  const mergedData = getAllInventory.map((inventoryItem) => {
    const kategoriBarang = getAllListKategoriBarang.find(
      (inventory) =>
        String(inventory.id) === String(inventoryItem.categories_id)
    );

    return {
      ...inventoryItem,
      itemcategory: kategoriBarang ? kategoriBarang.itemcategory : null,
    };
  });

  // const handleChangeSearch = (value) => {
  //   setSearchTerm(value);
  //   const filteredInventory = getAllInventory.filter((item) =>
  //     item.inventoryname.toLowerCase().includes(value.toLowerCase())
  //   );
  //   setGetAllInventory(filteredInventory);
  // };

  // const handleChangeSearch = (value) => {
  //   setSearchTerm(value);

  //   if (value === "") {
  //     // Kembalikan ke daftar lengkap
  //     setGetAllInventory([...getAllInventory]);
  //   } else {
  //     const filteredInventory = getAllInventory.filter((item) =>
  //       item.inventoryname.toLowerCase().includes(value.toLowerCase())
  //     );
  //     setGetAllInventory([...filteredInventory]);
  //   }
  // };

  const handleChangeSearch = (e) => {
    console.log(e);
    const searchText = e.target.value.toLowerCase();

    if (searchText === "") {
      setGetAllInventory(searchTerm);
    } else {
      const filteredData = searchTerm.filter((item) =>
        Object.values(item).some(
          (value) =>
            typeof value === "string" &&
            value.toLowerCase().includes(searchText)
        )
      );
      setGetAllInventory(filteredData);
    }
  };

  const handleFilterStatus = (e) => {
    setStatusSelected(e);

    if (e === "all") {
      setGetAllListKategoriBarang(searchTerm2);
    } else {
      let filteredData;

      if (dataCommitment !== "all") {
        filteredData = searchTerm2.filter(
          (item) =>
            item.categories_id.toUpperCase() === dataCommitment.toUpperCase()
        );
      } else {
        filteredData = searchTerm2.filter(
          (item) => item.categories_id.toUpperCase() === e.toUpperCase()
        );
      }
      setDataCommitment(filteredData);
    }
  };

  // const handleChangeSearch = (e) => {
  //   const searchText = e.target.value.toLowerCase();

  //   if (searchText === "") {
  //     // If searchText is empty, show the entire inventory list
  //     setGetAllInventory(searchTerm);
  //   } else {
  //     // If searchText is not empty, filter the inventory based on the search text
  //     const filteredData = searchTerm.filter((item) =>
  //       Object.values(item).some(
  //         (value) =>
  //           typeof value === "string" &&
  //           value.toLowerCase().includes(searchText)
  //       )
  //     );
  //     setGetAllInventory(filteredData);
  //   }
  // };



  const listStatus = getAllListKategoriBarang.map((category) => ({
    label: category.itemcategory,
    value: category.id,
  }));


  return (
    // <DashboardLayout>
    <div className={styles.wrapper}>
      <div className={styles.main}>
        <div className={styles.wrapperMenu}>
          <div className={styles.wrapperComponent}>
            <div className={styles.searchBox}>
              <Search
                placeholder="Masukkan kata kunci pencarian"
                allowClear
                enterButton="Cari"
                size="large"
                onChange={handleChangeSearch}
                style={{ width: 400 }}
              />
              <Select
                defaultValue="Semua"
                style={{
                  width: 250,
                  height: 35,
                }}
                onChange={handleFilterStatus}
                // options={listStatus}
                placeholder="Filter berdasarkan status"
                filterOption={(input, option) =>
                  (option?.label ?? "")
                    .toLowerCase()
                    .includes(input.toLowerCase())
                }
                options={getAllListKategoriBarang.map((item) => ({
                  value: item.id,
                  label: item.itemcategory,
                }))}
              ></Select>
            </div>
            {(role === "admin" || role === "superadmin") && (
              <div className={styles.tableMenu}>
                <div className={styles.containerButton}>
                  <div className={styles.buttonMenu1}>
                    <NavLink
                      to="/persediaan/add-persediaan"
                      className={styles.btnAddJobs}
                    >
                      Tambah Data
                    </NavLink>
                  </div>
                  {/* <div className={styles.wrapperButton}>
                    <span
                      className={styles.btnAddJobs1}
                      onClick={handledownload}
                    >
                      Export Data
                    </span>
                  </div> */}
                  {/* <div>
                    <input type="date" value={startDate} onChange={(e) => setStartDate(e.target.value)} />
                    <input type="date" value={endDate} onChange={(e) => setEndDate(e.target.value)} />
                    <button onClick={handledownload}>Export Data</button>
                  </div> */}
                </div>
              </div>
            )}
          </div>
          <Table
            className={styles.tableCustomers}
            bordered
            dataSource={mergedData}
            columns={columns}
            pagination={{
              pageSize: 10,
              onChange: (page, pageSize) => {
                setStartIndex((page - 1) * pageSize + 1);
              },
            }}
          />
        </div>
      </div>
    </div>
    // </DashboardLayout>
  );
}

export default Persediaan;
