import React, { useCallback, useState } from "react";
import DashboardLayout from "../../../layouts/dashboard-layout/DashboardLayout";
import styles from "./Pengeluaran.module.css";
import {
  Input,
  Breadcrumb,
  message,
  Alert,
  Select,
  Radio,
  DatePicker,
  Space,
  Skeleton,
  Table,
  Tooltip,
  Modal,
  Tabs,
  Button,
} from "antd";
import { ExclamationCircleOutlined, HomeOutlined } from "@ant-design/icons";
import axios from "axios";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import { BASE_API_URL } from "../../../constant/url";
import { AiFillDelete, AiFillEdit, AiFillEye } from "react-icons/ai";
import { useEffect } from "react";
import Search from "antd/es/input/Search";
// import { Table } from "react-bootstrap";
const { RangePicker } = DatePicker;
const onChange = (value, dateString) => {
  console.log("Selected Time: ", value);
  console.log("Formatted Selected Time: ", dateString);
};
const onOk = (value) => {
  console.log("onOk: ", value);
};
const { confirm } = Modal;
const { TabPane } = Tabs;

function Pengeluaran() {
  const role = localStorage.getItem("role");
  const acccesToken = localStorage.getItem("accessToken");
  const navigate = useNavigate();
  const [startIndex, setStartIndex] = useState(1);
  const location = useLocation();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [open, setOpen] = useState(false);
  const logout = () => {
    localStorage.removeItem("accessToken");
    localStorage.removeItem("role");
    localStorage.removeItem("user");
    localStorage.removeItem("token");
    message.error("Logout berhasil");
    navigate("/");
  };
  const [loading, setLoading] = useState(false);
  const [getAllSpending, setGetAllSpending] = useState([]);
  const [getAllInventory, setGetAllInventory] = useState([]);
  const [openAlert, setOpenAlert] = useState(false);
  const [messageVal, setMessageVal] = useState("");
  const [searchTerm, setSearchTerm] = useState([]);
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [pageSize, setPageSize] = useState(20);
  const [isFirstLoad, setIsFirstLoad] = useState(true);

  const columns = [
    {
      title: "No",
      width: "10%",
      dataIndex: "index",
      key: "index",
      render: (text, record, index) => <span>{startIndex + index}</span>,
    },
    {
      title: "Tanggal Pengeluaran",
      dataIndex: "spendingsdate",
      key: "spendingsdate",
      render: (startDate) => {
        const options = { year: 'numeric', month: 'long', day: 'numeric' };
        const formattedDate = new Date(startDate).toLocaleDateString('id-ID', options);
        return <span>{formattedDate}</span>;
      },
    },
    {
      title: "Nama Pengeluaran",
      dataIndex: "inventoryname",
      key: "inventoryname",
    },
    {
      title: "Nomor Pengeluaran",
      dataIndex: "nospendings",
      key: "nospendings",
    },
    {
      title: "Jumlah Pengeluaran",
      dataIndex: "totalspendings",
      key: "totalspendings",
      render: (totalspendings) => {
        const formattedTotal = parseFloat(totalspendings).toLocaleString();
        return <span>{formattedTotal}</span>;
      },
      align: 'right',
    },
    {
      title: "Satuan",
      dataIndex: "unitspendings",
      key: "unitspendings",
    },
    {
      title: "Lokasi",
      dataIndex: "locationspendings",
      key: "locationspendings",
    },
    {
      title: "Diminta Oleh",
      dataIndex: "requestspendings",
      key: "requestspendings",
    },
    {
      title: "Disetujui Oleh",
      dataIndex: "acceptspendings",
      key: "acceptspendings",
    },
    {
      title: "Action",
      width: "5%",
      key: "action",
      render: (_, record) => (
        <div className={styles.wrapperIcon}>
          <Space size="middle">
            <NavLink to={`/pengeluaran/detail-pengeluaran/${record.id}`}>
              <Tooltip
                placement="bottom"
                title={`Klik untuk lihat detail pengeluaran barang`}
              >
                <AiFillEye className={styles.iconActionView} />
              </Tooltip>
            </NavLink>
          </Space>
          <div className={styles.btngroup}>
            <NavLink
              to={`/pengeluaran/edit-pengeluaran/${record.id}`}
              className={styles.btnEditCustomer}
            >
              <AiFillEdit />
            </NavLink>
          </div>
        </div>
      ),
    },
  ];

  // useEffect(() => {
  //   setLoading(true);
  //   let config = {
  //     method: "get",
  //     url: `${BASE_API_URL}/v1/spendings/list-spendings`,
  //     headers: {
  //       Authorization: `Bearer ${acccesToken}`,
  //     },
  //   };

  //   axios(config)
  //     .then(function (response) {
  //       setGetAllSpending(response.data.data);
  //       setSearchTerm(response.data.data);
  //       console.log(response.data.data);
  //       setLoading(false);
  //     })
  //     .catch(function (error) {
  //       console.log(error);
  //       if (error.response.status === 401) {
  //         logout();
  //         message.error("Your session has expired, please log in again");
  //       }
  //       setLoading(false);
  //     });
  // }, []);

  const fetchSpendings = useCallback(async () => {
    if (!isFirstLoad) return; // Prevent the first call if not necessary

    setLoading(true);
    const config = {
      method: 'get',
      url: `${BASE_API_URL}/v1/spendings/list-spendings?page=${currentPage}&limit=${pageSize}`,
      headers: {
        Authorization: `Bearer ${acccesToken}`,
      },
    };

    try {
      const response = await axios(config);
      setGetAllSpending(response.data.data);
      setTotalPages(response.data.pagination.totalPages); // Set total pages from backend
      setLoading(false);
      setIsFirstLoad(false); // Set to false after first successful load
    } catch (error) {
      console.log(error);
      if (error.response && error.response.status === 401) {
        logout();
        message.error('Your session has expired, please log in again');
      }
      setLoading(false);
    }
  }, [currentPage, pageSize, acccesToken, BASE_API_URL, logout, isFirstLoad]);

  useEffect(() => {
    fetchSpendings(); // Fetch spendings only when currentPage or pageSize changes
    console.log("test pagiantion", fetchSpendings)
  }, [fetchSpendings]);

  // Handle page change from table pagination
  const handleTableChange = (page, pageSize) => {
    setCurrentPage(page);
    setPageSize(pageSize);
    setIsFirstLoad(true); // Allow fetching when page changes
  };



  //List Inventories
  useEffect(() => {
    setLoading(true);
    let config = {
      method: "get",
      url: `${BASE_API_URL}/v1/inventory/list-inventory`,
      headers: {
        Authorization: `Bearer ${acccesToken}`,
      },
    };

    axios(config)
      .then(function (response) {
        setGetAllInventory(response.data.data);
        console.log("pengelyara", response.data.data);
        setLoading(false);
      })
      .catch(function (error) {
        console.log(error);
        if (error.response.status === 401) {
          logout();
          message.error("Your session has expired, please log in again");
        }
        setLoading(false);
      });
  }, []);

  const deleteSpending = (value) => {
    confirm({
      title: "Are you sure you want to delete the items ?",
      icon: <ExclamationCircleOutlined />,
      okText: "Delete",
      okType: "danger",
      cancelText: "Cancel",
      onOk() {
        let config = {
          method: "delete",
          url: `${BASE_API_URL}/v1/spendings/deletespending/${value}`,
          headers: {
            Authorization: `Bearer ${acccesToken}`,
            "Content-Type": "application/json",
          },
        };
        axios(config)
          .then(function (response) {
            message.success("Hapus Persediaan Berhasil");
            window.location.reload();
          })
          .catch(function (error) {
            message.error("Delete Items Failed");
            if (error.response.status === 401) {
              logout();
              message.error("Your session has expired, please log in again");
            }
            setLoading(false);
          });
      },
      onCancel() { },
    });
  };

  //excel

  const handledownload = async () => {
    setLoading(true);

    try {
      const response = await axios.post(
        `${BASE_API_URL}/v1/spendings/spendings-excel`,
        { startDate, endDate },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
          },
          responseType: "blob",
        }
      );

      // Create a Blob from the binary data
      const blob = new Blob([response.data], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });

      const link = document.createElement("a");
      link.href = window.URL.createObjectURL(blob);
      link.download = "Spendings.xlsx";
      document.body.appendChild(link);

      link.click();

      setLoading(false);
    } catch (error) {
      setLoading(false);
      if (error.response && error.response.status === 401) {
        logout();
        setOpenAlert(true);
        setMessageVal(`${error.response.data.message}`);
      } else if (error.response && error.response.data.statusCode === 4002) {
        setOpenAlert(true);
        setMessageVal(`${error.response.data.message}`);
      } else if (error.response && error.response.status === 400) {
        setOpenAlert(true);
        setMessageVal(`${error.response.data.message}`);
      } else {
        message.error("Unknown Error");
      }
      console.log(error);
    }
  };

  const mergedData = getAllSpending.map((spendingItem) => {
    const inventoryItem = getAllInventory.find(
      (inventory) =>
        String(inventory.id) === String(spendingItem.inventories_id)
    );

    return {
      ...spendingItem,
      inventoryname: inventoryItem ? inventoryItem.inventoryname : null,
    };
  });
  mergedData.sort((a, b) => new Date(b.spendingsdate) - new Date(a.spendingsdate));

  // console.log("mergedata", mergedData);

  const handleChangeSearch = (e) => {
    const searchText = e.target.value.toLowerCase();

    if (searchText === "") {
      setGetAllSpending(searchTerm);
    } else {
      const filteredData = getAllSpending
        .map((registerItem) => {
          const inventoryItem = getAllInventory.find(
            (inventory) =>
              String(inventory.id) === String(registerItem.inventories_id)
          );

          return {
            ...registerItem,
            inventoryname: inventoryItem ? inventoryItem.inventoryname : null,
          };
        })
        .filter((item) =>
          Object.values(item).some(
            (value) =>
              typeof value === "string" &&
              value.toLowerCase().includes(searchText)
          )
        );

      setGetAllSpending(filteredData);
    }
  };

  const showModal = () => {
    setOpen(true);
  };
  const handleOk = () => {
    setOpen(false);
  };
  const handleCancel = () => {
    setOpen(false);
  };
  return (

    <DashboardLayout>
      {loading ? (
        <Skeleton />
      ) : (
        <div className={styles.wrapper}>
          <div className={styles.topWrapper}>
            <h2 className={styles.pageInfoTitle}>Pengeluaran Barang</h2>
            <Breadcrumb
              className={styles.breadcumbs}
              items={[
                {
                  href: "/dashboard",
                  title: <HomeOutlined />,
                },
                {
                  href: "/Pengeluaran Barang",
                  title: <span>Pengeluaran Barang</span>,
                },
              ]}
            />
          </div>

          <div className={styles.main}>
            <div className={styles.tableMenu}>
              <div className={styles.searchBox}>
                <Input.Search
                  placeholder="Masukkan kata kunci pencarian"
                  allowClear
                  enterButton="Cari"
                  size="large"
                  onSearch={(value) => setSearchTerm(value)} // Update search term
                  style={{ width: 400 }}
                />
              </div>

              <div className={styles.containerButton}>
                <NavLink to="/pengeluaran/add-pengeluaran" className={styles.btnAddJobs}>
                  Tambah Data
                </NavLink>
                <div className={styles.wrapperButton}>
                  <Button className={styles.btnAddJobs1} type="primary" onClick={showModal}>
                    Export Data
                  </Button>
                  <Modal
                    className={styles.modalexport}
                    open={open}
                    title="Export Data"
                    onOk={handleOk}
                    onCancel={handleCancel}
                    footer={(_, { OkBtn, CancelBtn }) => (
                      <>
                        <Button onClick={handledownload}>Export Data</Button>
                      </>
                    )}
                  >
                    <div className={styles.wrapperdate}>
                      <div className={styles.wrapperdatetop}>
                        <p className={styles.textdate}>Mulai Tanggal</p>
                        <Input className={styles.inputdate} type="date" value={startDate} onChange={(e) => setStartDate(e.target.value)} />
                      </div>
                      <div className={styles.wrapperdatebottom}>
                        <p className={styles.textdate}>Sampai Tanggal</p>
                        <Input className={styles.inputdate} type="date" value={endDate} onChange={(e) => setEndDate(e.target.value)} />
                      </div>
                    </div>
                  </Modal>
                </div>
              </div>
            </div>

            <Table
              className={styles.tableCustomers}
              bordered
              dataSource={mergedData}
              columns={columns} // Assuming columns is defined elsewhere
              pagination={{
                current: currentPage,
                pageSize,
                total: totalPages * pageSize, // Total items calculated from totalPages
                onChange: handleTableChange, // Pagination handler
              }}
            />
          </div>
        </div>
      )}
    </DashboardLayout>
  );
}

export default Pengeluaran;