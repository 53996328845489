import React, { useState } from "react";
import styles from "./AddPersediaan.module.css";
import { Alert, Input, Select, message } from "antd";
import { NavLink, useNavigate } from "react-router-dom";
import DashboardLayout from "../../../../layouts/dashboard-layout/DashboardLayout";
import { BASE_API_URL } from "../../../../constant/url";
import axios from "axios";
import { useEffect } from "react";
function AddPersediaanBarang() {
  const [loading, setLoading] = useState(false);
  const acccesToken = localStorage.getItem("accessToken");
  const [inventory, setInventory] = useState({
    inventoryname: "",
    descriptionproduk: "",
    periode: "",
    location: "",
    unit: "",
    total: "",
    categories_id: "",
    date_update: "",
  });
  const [getAllListBarangRegister, setGetAllListBarangRegister] = useState([]);
  const [openAlert, setOpenAlert] = useState(false);
  const [messageVal, setMessageVal] = useState("");
  const [selectedKategori, setSelectedKategori] = useState(null);
  const navigate = useNavigate();
  const logout = () => {
    localStorage.removeItem("accessToken");
    localStorage.removeItem("role");
    localStorage.removeItem("user");
    localStorage.removeItem("token");
    message.error("Logout berhasil");
    navigate("/");
  };

  //handle change
  const handleChange = (event) => {
    setInventory({
      ...inventory,
      [event.target.name]: event.target.value,
    });
  };

  //Handle select kategori

  const handleBarangChange = (value) => {
    setSelectedKategori(value);
    setInventory({
      ...inventory,
      categories_id: value,
    });
  };

  //handle input
  const handleInsertNewItems = async () => {
    setLoading(true);

    try {
      if (!inventory.inventoryname) {
        setOpenAlert(true);
        setMessageVal("Tolong Masukkan Nama Barang");
        setLoading(false);
        return;
      }

      const response = await axios.post(
        `${BASE_API_URL}/v1/inventory/inventory`,
        inventory,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
          },
        }
      );
      message.success("Data Berhasil Ditambahkan");
      setLoading(false);
      setTimeout(() => {
        navigate("/persediaan");
      }, 500);
    } catch (error) {
      setLoading(false);
      if (error.response.status === 401) {
        logout();
        // message.error("sesi anda telah habis, login ulang");
        setOpenAlert(true);
        setMessageVal(`${error.response.data.message}`);
      } else if (error.response.data.statusCode == 4002) {
        setOpenAlert(true);
        setMessageVal(`${error.response.data.message}`);
        // message.error("Email telah terdaftar");
      } else if (error.response.status === 400) {
        setOpenAlert(true);
        setMessageVal(`${error.response.data.message}`);
        // message.error("Pastikan semua data telah terisi");
      } else {
        message.error("Unknown Error");
      }
      console.log(error);
    }
  };

  //List Kategori
  useEffect(() => {
    setLoading(true);
    let config = {
      method: "get",
      url: `${BASE_API_URL}/v1/sparepart/list-sparepart-category`,
      headers: {
        Authorization: `Bearer ${acccesToken}`,
      },
    };

    axios(config)
      .then(function (response) {
        setGetAllListBarangRegister(response.data.data);
        console.log(response.data.data);
        setLoading(false);
      })
      .catch(function (error) {
        console.log(error);
        if (error.response.status === 401) {
          logout();
          message.error("Your session has expired, please log in again");
        }
        setLoading(false);
      });
  }, []);

  return (
    <DashboardLayout>
      <div className={styles.wrapper}>
        <div className={styles.content}>
          <h4 className={styles.addAdminTitle}>Add Data</h4>
          <div className={styles.form}>
            <div className={styles.alertLogin}>
              {openAlert && (
                <Alert
                  className={styles.alertText}
                  message={messageVal}
                  type="error"
                  showIcon
                  closable
                  onClick={() => {
                    setOpenAlert(false);
                  }}
                />
              )}
            </div>
            <div className={styles.formGroup}>
              <label htmlFor="companyname" className={styles.formLabel}>
                Nama Persediaan
              </label>
              <Input
                required="true"
                type="text"
                name="inventoryname"
                value={inventory.inventoryname}
                onChange={handleChange}
                className={styles.formControl}
                placeholder="Input Item Name"
              />
            </div>

            <div className={styles.formGroup}>
              <label htmlFor="companyname" className={styles.formLabel}>
                Kategori Barang
              </label>

              <Select
                className={styles.formControl}
                showSearch
                placeholder="Pilih kategori barang"
                optionFilterProp="children"
                onChange={handleBarangChange}
                value={selectedKategori}
                filterOption={(input, option) =>
                  (option?.label ?? "")
                    .toLowerCase()
                    .includes(input.toLowerCase())
                }
                options={getAllListBarangRegister.map((item) => ({
                  value: item.id,
                  label: item.itemcategory,
                }))}
              />
            </div>

            {/* <div className={styles.formGroup}>
              <label htmlFor="companyname" className={styles.formLabel}>
                Deskripsi Persediaan
              </label>
              <Input
                required="true"
                type="text"
                name="descriptionproduk"
                value={inventory.descriptionproduk}
                onChange={handleChange}
                className={styles.formControl}
                placeholder="Input Item Name"
              />
            </div> */}
            {/* <div className={styles.formGroup}>
              <label htmlFor="companyname" className={styles.formLabel}>
                Periode
              </label>
              <Input
                required="true"
                type="text"
                name="periode"
                value={inventory.periode}
                onChange={handleChange}
                className={styles.formControl}
                placeholder="Input Item Name"
              />
            </div> */}
            {/* <div className={styles.formGroup}>
              <label htmlFor="companyname" className={styles.formLabel}>
                Lokasi
              </label>
              <Input
                required="true"
                type="text"
                name="location"
                value={inventory.location}
                onChange={handleChange}
                className={styles.formControl}
                placeholder="Input Item Name"
              />
            </div> */}
            <div className={styles.formGroup}>
              <label htmlFor="companyname" className={styles.formLabel}>
                Jenis Satuan
              </label>
              <Input
                required="true"
                type="text"
                name="unit"
                value={inventory.unit}
                onChange={handleChange}
                className={styles.formControl}
                placeholder="Masukkan jenis satuan"
              />
            </div>
            <div className={styles.formGroup}>
              <label htmlFor="companyname" className={styles.formLabel}>
                Tanggal terupdate
              </label>
              <Input
                required="true"
                type="date"
                name="date_update"
                value={inventory.date_update}
                onChange={handleChange}
                className={styles.formControl}
                placeholder="Masukkan jenis satuan"
              />
            </div>
            <div className={styles.btnBox}>
              {loading ? (
                <button className={styles.btnAdd}>Loading...</button>
              ) : (
                <button
                  className={styles.btnAdd}
                  onClick={handleInsertNewItems}
                >
                  Add Data
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
    </DashboardLayout>
  );
}

export default AddPersediaanBarang;
